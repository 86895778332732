import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/header";
import { Breadcrumb, Button, Modal, Table } from "react-bootstrap";
import CustomLoader from "../Components/CustomeLoader";
import enUS from "date-fns/locale/en-US";
import { handleGetRedeemActivity } from "../shared/api";
import moment from "moment";
import { API_URL, Node_BE_URL } from "../constant";
import { DateRange } from "react-date-range"; // Import DateRange
import "react-date-range/dist/styles.css"; // Include styles for the date range picker
import "react-date-range/dist/theme/default.css";
import axios from "axios";
import PaginationComponent from "./PaginationComponent";
import ImageModal from "./ImageModal";
import LocationModal from "./LocationModal";
import { toast } from "react-toastify";

const RedemptionActivity = () => {
  const [activities, setActivities] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [loader, setloader] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [locations, setLocations] = useState([]);
  const [checked, setchecked] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [productsLoading, setProductsLoading] = useState(true);
  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const getUserList = async (data) => {
    setloader(true);
    const { startDate, endDate } = dateRange[0];

    await handleGetRedeemActivity(
      currentPage,
      startDate,
      endDate,
      selectedLocation?.id
    )
      .then((res) => {
        const redeemedActions = res?.data?.redeemedActions?.map((activity) => {
          const product = data?.find(
            (p) => p?.id === activity?.Details?.ProductId
          );
          return {
            ...activity,
            Details: {
              ...activity.Details,
              // ProductName: product ? product.name : "Unknown Product",
            },
          };
        });
        setActivities(redeemedActions);
        setTotalItems(res?.data?.pagination?.totalRedeemedActions);
        setloader(false);
      })
      .catch((err) => {
        setloader(false);
      });
  };

  /**
   * Fetches a list of products from the API and stores them in a state object
   */
  const GetListsData = async () => {
    setProductsLoading(true);
    await axios
      .get(`${Node_BE_URL}/api/getProducts`)
      .then((res) => {
        getUserList(res.data.data);
        setProductsLoading(false);
      })
      .catch(() => {
        setProductsLoading(false);
      });
  };

  useEffect(() => {
    GetListsData();
  }, [currentPage, dateRange, selectedLocation]);

  const handleCloseModal = () => {
    setShowModal(false);
    setDateRange([
      {
        startDate: null,
        endDate: null,
        key: "selection",
      },
    ]);
  };
  const handleFilter = () => {
    setShowModal(false);
    getUserList();
  };
  const getDistributionTypeName = (type) => {
    switch (type) {
      case 4:
        return "Promotion";
      case 3:
        return "Tier";
      case 2:
        return "Product";
      default:
        return "Unknown";
    }
  };

  const handleImageClick = (imageUrl) => {
    setModalImage(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage("");
  };

  const GetAllLocations = () => {
    axios
      .get(`${Node_BE_URL}/api/admin/getAllLocations`)
      .then((data) => {
        setLocations(data.data);
      })
      .catch((err) => {});
  };

  // Fetch locations when component mounts
  useEffect(() => {
    GetAllLocations();
  }, []);
  // Update the selected location
  const handleLocationSelect = (location) => {
    setSelectedLocation(location);
    setModalShow(false);
  };

  return (
    <>
      <Sidebar />
      <CustomLoader isLoading={loader} />

      <main className="dasboard-main">
        <div className="dashboard-inner-pnl">
          <Header />
          <div className="head-inner-panel ">
            <div className="full-div">
              {/* BreadCrumb */}
              <Breadcrumb>
                <Breadcrumb.Item> Admin Management </Breadcrumb.Item>
                <Breadcrumb.Item href="#">Redemption Activity</Breadcrumb.Item>
              </Breadcrumb>
              {/* BreadCrumb */}
            </div>

            <div className="full-div">
              <div className="flex-div-sm">
                <h1>
                  Redemption Activity <span className="view-title"></span>
                </h1>

                {/* </div> */}
                <ul className="btn-lister">
                  <li>
                    <div className="search-pnl">
                      {/* <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <Searchbar setsearchedString={setsearchedString} handleSearch={handleSearch} searchedString={searchedString} />
                      </Form> */}
                    </div>
                  </li>
                  {selectedLocation && (
                    <div>
                      <p>Selected Location Name: {selectedLocation.name}</p>
                    </div>
                  )}
                  <div
                    className="reg-btn mr-2 mb-2 reset-container"
                    onClick={() => setModalShow(true)}
                  >
                    Select Store
                  </div>
                  <LocationModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    locations={locations}
                    onSelect={handleLocationSelect}
                    initialLocation={selectedLocation}
                  />

                  <div
                    onClick={() => {
                      setchecked(null);
                      setCurrentPage(1);
                      setSelectedLocation(null);
                      setDateRange([
                        {
                          startDate: null,
                          endDate: null,
                          key: "selection",
                        },
                      ]);
                    }}
                    className="reg-btn mr-2 mb-2"
                    style={{ cursor: "pointer" }}
                  >
                    Reset
                  </div>
                  <Button
                    className="reg-btn"
                    onClick={() => setShowModal(true)}
                  >
                    <i className="fa fa-plus"></i>Select Date Range
                  </Button>
                </ul>
              </div>
            </div>

            <div className="white-div">
              <div className="table-container">
                <div className="table-container-inner">
                  <Table>
                    <thead>
                      <tr>
                        <th>NFT Name</th>
                        <th>Product Name</th>
                        <th>Price</th>
                        <th>Redeem Date</th>
                        <th>NFT Type</th>
                        <th>Store Name</th>
                        <th>Employee Id/Name</th>
                        <th>Discount Code</th>
                      </tr>
                    </thead>

                    {!loader ? (
                      activities?.length > 0 ? (
                        <tbody>
                          {activities.map((activity) => (
                            <tr key={activity._id}>
                              <td>
                                <img
                                  className="Redemptionimg"
                                  onClick={() =>
                                    handleImageClick(
                                      `${API_URL}${
                                        activity.Details.ActiveImage ||
                                        activity.Details.Image
                                      }`
                                    )
                                  }
                                  src={`${API_URL}${
                                    activity.Details.ActiveImage ||
                                    activity.Details.Image
                                  }`}
                                  alt=""
                                />
                                {activity.Details.NftName}
                              </td>

                              <td>{activity.Details.ProductName}</td>
                              <td>{activity.Details.Price}</td>
                              <td>{moment(activity?.Timestamp).format( "YYYY-MM-DD HH:mm" )} </td>
                              <td> {getDistributionTypeName(activity.Details.DistributionType)} </td>
                              <td>{activity.locationName}</td>
                              <td
                                onClick={() => {navigator.clipboard.writeText( activity.EmployeeId );
                                  toast.success("Employee ID copied to clipboard!",{
                                    autoClose: 1500,
                                  }); 
                                }}
                                style={{ cursor: "pointer" }}
                              >
                               {`${activity.EmployeeId.slice(0, 8)}........${activity.EmployeeId.slice(-6)} (${activity.EmployeeName})`}</td>
                              <td>{activity.Details.DiscountCode}</td>
                            </tr>
                          ))}
                        </tbody>
                      ) : (
                        <tbody
                          style={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <tr>
                            <td colSpan="5">No Record Found</td>
                          </tr>
                        </tbody>
                      )
                    ) : (
                      <CustomLoader isLoading={loader} />
                    )}
                  </Table>
                </div>
              </div>

              <div className="full-div text-center pagination-container">
                <PaginationComponent
                  totalItems={totalItems}
                  itemsPerPage={10}
                  currentPage={currentPage}
                  onPageChange={(pageNumber) => {
                    setCurrentPage(pageNumber);
                  }}
                  customClassNames={{
                    rpbItemClassName: "custom-item",
                    rpbItemClassNameActive: "custom-item--active",
                    rpbGoItemClassName: "custom-go-item",
                    rpbItemClassNameDisable: "custom-item--disable",
                    rpbProgressClassName: "custom-progress-bar",
                    rpbRootClassName: "custom-root",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <Modal centered show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header>
            <Button
              closeButton
              className="close-btn"
              onClick={handleCloseModal}
            >
              <i className="fa fa-close"></i>
            </Button>

            <Modal.Title>Select Date Range</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="date-picker-container">
              <DateRange
                onChange={(item) => setDateRange([item.selection])}
                ranges={dateRange}
                locale={enUS} // Add this line to set the locale
                editableDateInputs
                months={1}
                direction="horizontal"
                moveRangeOnFirstSelection={false}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleFilter}>
              Filter
            </Button>
          </Modal.Footer>
        </Modal>
        <ImageModal
          modalImage={modalImage}
          isModalOpen={isModalOpen}
          closeModal={closeModal}
        />
      </main>
    </>
  );
};

export default RedemptionActivity;
