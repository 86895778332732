import React, { useState, useEffect } from 'react';
import { Searchbar } from './Searchbar';
import ClipLoader from "react-spinners/ClipLoader";
import InfiniteScroll from 'react-infinite-scroll-component';
import { ListGroup } from 'react-bootstrap';
import axios from 'axios';
import { Node_BE_URL, colors } from '../constant';
import { SearchComp } from './SearchComp';

const InfiniteScrollList = ({ closeModal, count, onPress }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const itemsPerPage = 200;
  const totalPages = Math.ceil(count / itemsPerPage);

  // Function to fetch data for infinite scrolling
  const GetListsData = async (Page) => {
    setLoading(true);
    try {
      const res = await axios.get(`${Node_BE_URL}/api/getProducts`, {
        params: { page: Page },
      });
      const fetchedData = res?.data?.data || [];
      setData((prev) => [...prev, ...fetchedData]);
      if (Page >= totalPages || fetchedData.length < itemsPerPage) {
        setHasMore(false);
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    } finally {
      setLoading(false);
    }
  };

  // Function to handle search
  const handleSearch = async () => {
    setLoading(true);
    setData([]); // Clear existing data
    setHasMore(false); // Disable infinite scroll during search
    try {
      const res = await axios.post(`${Node_BE_URL}/api/getProductsbyName`, {
        search: searchQuery,
      });
      setData(res?.data?.data || []);
    } catch (err) {
      console.error('Error searching:', err);
    } finally {
      setLoading(false);
    }
  };

  // Effect to fetch default data when the search query is cleared
  useEffect(() => {
    if (!searchQuery) {
      setPage(1);
      setData([]);
      setHasMore(true);
      GetListsData(1);
    }
  }, [searchQuery]);

  // Function to fetch the next page for infinite scrolling
  const nextData = () => {
    const nextPage = page + 1;
    setPage(nextPage);
    GetListsData(nextPage);
  };

  return (
    <div>
      <div className="search-pnl mb-4">
        <SearchComp
          setsearchedString={setSearchQuery}
          handleSearch={handleSearch}
          searchedString={searchQuery}
        />
      </div>
      <div id="scrollableDiv" style={{ maxHeight: '500px', overflowY: 'scroll' }}>
        <InfiniteScroll
          dataLength={data.length}
          next={!searchQuery && nextData}
          hasMore={!searchQuery && hasMore}
          scrollableTarget="scrollableDiv"
          loader={
            loading && (
              <div className="loader-style">
                <ClipLoader color={colors.mainColor} loading={loading} size={30} />
              </div>
            )
          }
        >
          <ListGroup variant="flush">
            {!loading && data.length === 0 && <p>No Record Found.</p>}
            {data?.map((item, index) => (
              <ListGroup.Item
                key={index}
                style={{ maxHeight: item?.name?.length > 54 ? '100px' : '75px' }}
                action
                onClick={() => {
                  closeModal();
                  onPress(item);
                }}
              >
                {item?.name}
                {item?.sku && (
                  <p id="para">
                    {item?.sku} {`(${item?.companyid})`}
                  </p>
                )}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default InfiniteScrollList;
