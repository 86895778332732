import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/header";
import { Breadcrumb, Button, Table } from "react-bootstrap";
import CustomLoader from "../Components/CustomeLoader";

import { handleSyncinventory, handleSyncProduct } from "../shared/api";
import { CompanyLocation } from "../constant";
import { toast } from "react-toastify";

const UpdateProduct = () => {
  const [syncingCompany, setSyncingCompany] = useState(null);
  const [syncingInventory, setSyncingInventory] = useState(null);
  const [loader, setloader] = useState(true);
  const [load, setload] = useState(true);

  useEffect(() => {
    setloader(false);
    setload(false);
  }, []);

  const handleSync = async (companyId) => {
    setSyncingCompany(companyId);
    setloader(true);
    try {
      const res = await handleSyncProduct(Number(companyId));

      toast.success(res.data.message);
      setloader(false);
    } catch (error) {
      toast.error(`Sync failed for company ID: ${companyId}`, error);
    } finally {
      setSyncingCompany(null);
    }
  };
  const handleInventorySync = async (companyId) => {
    setSyncingInventory(companyId);
    setload(true);
    try {
      const res = await handleSyncinventory(Number(companyId));

      toast.success(res.data.message);
      setload(false);
    } catch (error) {
      toast.error(`Sync failed for company ID: ${companyId}`, error);
    } finally {
      setSyncingInventory(null);
    }
  };

  return (
    <>
      <Sidebar />
      <CustomLoader isLoading={loader} />
      <CustomLoader isLoading={load} />

      <main className="dasboard-main">
        <div className="dashboard-inner-pnl">
          <Header />
          <div className="head-inner-panel ">
            <div className="full-div">
              {/* BreadCrumb */}
              <Breadcrumb>
                <Breadcrumb.Item> Admin Management </Breadcrumb.Item>
                <Breadcrumb.Item href="#">
                  Product and Inventory Sync{" "}
                </Breadcrumb.Item>
              </Breadcrumb>
              {/* BreadCrumb */}
            </div>

            <div className="full-div">
              <div className="flex-div-sm">
                <h1>
                  Product and Inventory Sync for Companies{" "}
                  <span
                    style={{
                      color: "#AD79F7",
                      fontSize: "16px",
                      fontWeight: "700",
                      marginLeft: "5px",
                    }}
                  ></span>
                </h1>

                <ul className="btn-lister">
                  <li></li>
                </ul>
              </div>
            </div>

            <div className="white-div">
              <div className="table-container">
                <div className="table-container-inner">
                  <Table>
                    <thead>
                      <tr>
                        <th>Company ID</th>
                        <th>Company Name</th>
                        <th>Action</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    {Object.entries(CompanyLocation)?.map(
                      ([companyId, companyName]) => (
                        <tr key={companyId}>
                          <td>{companyId}</td>
                          <td>{companyName}</td>
                          <td>
                            {syncingCompany === companyId ? (
                              <Button className="reg-btn w-100" disabled>
                                <span
                                  className="spinner-grow spinner-grow-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="sr-only">Syncing...</span>
                              </Button>
                            ) : (
                              <>
                                <Button
                                  className="reg-btn w-100"
                                  onClick={() => handleSync(companyId)}
                                >
                                   Sync Product
                                </Button>
                              </>
                            )}
                          </td>
                          <td>
                            {syncingInventory === companyId ? (
                              <Button className="reg-btn w-100" disabled>
                                <span
                                  className="spinner-grow spinner-grow-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="sr-only">Syncing...</span>
                              </Button>
                            ) : (
                              <>
                                <Button
                                  className="reg-btn w-100"
                                  onClick={() => handleInventorySync(companyId)}
                                >
                                  Sync Inventory
                                </Button>
                              </>
                            )}
                          </td>
                        </tr>
                      )
                    )}
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default UpdateProduct;
