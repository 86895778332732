import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/header";
import { Breadcrumb, Button, Table, Form, Pagination } from "react-bootstrap";
import CustomLoader from "../Components/CustomeLoader";
import moment from "moment";
import CopyIcon from "../assets/images/icons/copy.png";
import { handleGetAllTransactions } from "../shared/api";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";
import PaginationComponent from "../pages/PaginationComponent";
import { emailnumerRegex, phoneRegex, walletRegex } from "../constant";

const formatTransactionHash = (hash) => {
  if (!hash) return "";
  return `${hash.slice(0, 10)}...${hash.slice(-10)}`; // Show first 10 and last 10 characters
};

const DappTransection = () => {
  const [dataTransactions, setDataTransactions] = useState(true);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 10;
  const handleSearchChange = debounce((value) => {
    setSearchTerm(value);
    setCurrentPage(1); 
  }, 500);

const fetchTransactions = async () => {
  setLoading(true);
  try {
    const searchValue = searchTerm.trim();
    const payload = {
      pageSize: itemsPerPage,
      currentPage,
      searchTerm: searchValue,
    };

    const response = await handleGetAllTransactions(payload);

    setDataTransactions(response.transactions);
    setTotalItems(response.totalItems);
  } catch (error) {
    console.error("Error fetching transactions:", error);
    setDataTransactions([]);
    setTotalItems(0);
  } finally {
    setLoading(false);
  }
};
  

  useEffect(() => {
    fetchTransactions();
  }, [currentPage]);

  const handleCopyTransactionHash = (transaction) => {
    if (transaction?.transactionHash) {
      navigator.clipboard.writeText(transaction.transactionHash);
      toast.success("Transaction Hash copied!");
    }
  };

  return (
    <>
      <Sidebar />
      <CustomLoader isLoading={loading} />

      <main className="dasboard-main">
        <div className="dashboard-inner-pnl">
          <Header />
          <div className="head-inner-panel ">
            <div className="full-div">
              <Breadcrumb>
                <Breadcrumb.Item> Admin Management </Breadcrumb.Item>
                <Breadcrumb.Item href="#">Dapp Transaction</Breadcrumb.Item>
              </Breadcrumb>
            </div>
            <div className="Input_divmain">
            <div className="full-div mt-4">
              <div className="flex-div-sm">
              <h1 className="Activityhistory"> Dapp Transaction</h1>
                <ul className="btn-lister">
                  <li>
                    <div className="search-pnl">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          setCurrentPage(1); // Reset to first page on search (optional)
                          fetchTransactions(); // Call the API on form submit
                        }}
                      >
                        <div>
                          <Form.Group controlId="formBasicEmail">
                            <div className="input-group">
                              <Form.Control
                                type="search"
                                placeholder="Enter Address, Email, Phone"
                                className="form-control"
                                onChange={(e) =>
                                  handleSearchChange(e.target.value)
                                }
                              />

                              <div className="input-group-append">
                                <Button className="reg-btn" type="submit">
                                  <i className="fa fa-search"></i>
                                </Button>
                              </div>
                            </div>
                          </Form.Group>
                        </div>
                      </Form>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            </div>
            <div className="white-div">
              <div className="table-container">
                <div className="table-container-inner">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Action</th>
                        <th>Price</th>
                        <th>Hash</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Timestamp</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(dataTransactions) && dataTransactions.length > 0 ? (
                        dataTransactions?.map((transaction) => (
                          <tr key={transaction?.transactionHash}>
                            <td>
                              {transaction?.fromCurrency} to{" "}
                              {transaction?.toCurrency}
                            </td>
                            <td>{transaction?.amount}</td>
                            <td>
                              {formatTransactionHash(
                                transaction?.transactionHash
                              )}
                              <img
                                onClick={() =>
                                  handleCopyTransactionHash(transaction)
                                }
                                className="AmmountLogo"
                                src={CopyIcon}
                                alt="Copy"
                              />
                            </td>
                            <td>{transaction?.email}</td>
                            <td>{transaction?.phone}</td>
                            <td>
                              {moment(transaction?.completedAt).format(
                                "YYYY-MM-DD hh:mm A"
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="6" className="text-center">
                            No records found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
              <div className="full-div text-center pagination-container">
                <PaginationComponent
                  totalItems={totalItems}
                  itemsPerPage={itemsPerPage}
                  currentPage={currentPage}
                  onPageChange={(pageNumber) => {
                    setCurrentPage(pageNumber);
                    fetchTransactions();
                  }}
                  customClassNames={{
                    rpbItemClassName: "custom-item",
                    rpbItemClassNameActive: "custom-item--active",
                    rpbGoItemClassName: "custom-go-item",
                    rpbItemClassNameDisable: "custom-item--disable",
                    rpbProgressClassName: "custom-progress-bar",
                    rpbRootClassName: "custom-root",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default DappTransection;
