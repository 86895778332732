import React, { useState, useEffect } from "react";
import { Link, Routes, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import localforage from "localforage";

import logo from "../assets/images/logo.png";
import iconmanagement from "../assets/images/icons/icon-management.png";
import notificationiconwhite from "../assets/images/icons/notification.png";
import notificationcoloricon from "../assets/images/icons/notificationcoloricon.png";
import iconmanagementwhite from "../assets/images/icons/icon-management-white.png";
import iconcollection from "../assets/images/icons/icon-collection.png";
import iconcollectionwhite from "../assets/images/icons/icon-collection-white.png";
import iconpartner from "../assets/images/icons/icon-partner.png";
import iconpartnerwhite from "../assets/images/icons/icon-partner-white.png";
import iconcoupen from "../assets/images/icons/icon-coupen.png";
import radaeem from "../assets/images/icons/deadline_6888970.png";
import tarck from "../assets/images/icons/route_10567809.png";
import iconcoupenwhite from "../assets/images/icons/icon-coupen-white.png";
import iconbannerwhite from "../assets/images/icons/Vector.png";
import iconbanner from "../assets/images/icons/Vector-1.png";
import coins from "../assets/images/coins.png";
import StoreLink from "../assets/images/icons/Store_Links.png";
import StoreLink_white from "../assets/images/icons/Links_White.png";
import arrowDown from "../assets/images/icons/dropdown.png";
import "../Components/sidebar.scss";
import { givenRights, rightSelector } from "../redux/rightSlice";
import { accountSelector, fetchAccount } from "../redux/accountSlice";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { SideBar, titleStyle } from "../constant";

function Sidebar() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { rightAccess } = useSelector(rightSelector);
  const { account } = useSelector(accountSelector);

  const [toggle, setToggle] = useState(false);

  const toggleHandle = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    if (rightAccess?.length === 0) {
      localforage.getItem("Rights").then((value) => {
        dispatch(givenRights(value));
      });
    }
    if (!account?.email) {
      dispatch(fetchAccount());
    }
  }, [rightAccess, account, dispatch]);

  const UserRole = {
    SUPER_ADMINISTRATOR: "Super Administrator",
  };

  return (
    <div
      className={
        location.pathname === SideBar.BannerGeneration
          ? "sidebar active"
          : toggle
          ? "sidebar active"
          : "sidebar"
      }
    >
      {/* Logo */}
      <div className="text-center">
        <a href="/SubadminManagement">
          <img src={logo} alt="Logo" />
        </a>
      </div>

      {/* Menu */}
      <ul>
        {rightAccess?.some(
          (right) => right?.screenName?.toLowerCase() === "coupon management"
        ) && (
          <li
            className={`${
              location.pathname === "/CouponManagment" ? "active" : ""
            }`}
          >
            <Link to="/CouponManagment">
              <div className="img-pnl">
                <img src={iconcoupen} alt="Icon Coupen" />
                <img src={iconcoupenwhite} alt="Icon Coupen" />
              </div>{" "}
              NFT Coupon Management
            </Link>
          </li>
        )}

        {rightAccess?.some(
          (right) =>
            right.screenName.toLowerCase() === "nft collection management"
        ) && (
          <li
            className={`${
              location.pathname === "/NFTCollectionManagement" ? "active" : ""
            }`}
          >
            <Link to="/NFTCollectionManagement">
              <div className="img-pnl">
                <img src={iconcollection} alt="Icon Collection" />
                <img src={iconcollectionwhite} alt="Icon Collection" />
              </div>{" "}
              NFT Collection Management
            </Link>
          </li>
        )}

        {rightAccess?.some(
          (right) => right.roleName === UserRole.SUPER_ADMINISTRATOR
        ) && (
          <li
            className={`${
              location.pathname === "/RedemptionActivity" ? "active" : ""
            }`}
          >
            <Link to="/RedemptionActivity">
              <div className="img-pnl">
                <img src={radaeem} alt="Redemption Icon" />
                <img src={radaeem} alt="Redemption Icon White" />
              </div>{" "}
              Redemption Activity
            </Link>
          </li>
        )}

        {rightAccess?.some(
          (right) => right.roleName === UserRole.SUPER_ADMINISTRATOR
        ) && (
          <li className={`${location.pathname == "/Coupon" ? "active" : ""}`}>
            <Link to="/Coupon">
              <div className="img-pnl">
                <img src={iconcoupen} alt="Redemption Icon" />
                <img src={iconcoupenwhite} alt="Redemption Icon White" />
              </div>{" "}
              Coupon
            </Link>
          </li>
        )}

        {rightAccess?.some(
          (right) => right.roleName === UserRole.SUPER_ADMINISTRATOR
        ) && (
          <DropdownButton
            id="dropdown-basic-button"
            title={
              <div className="Coin-managment">
                <div className="img-pnl">
                  <img className="SignUpManagment" src={tarck} alt="tarck" />
                </div>
                Signup Management
                <img
                  src={arrowDown}
                  alt="arrow-down"
                  className="Arrow-DropDown"
                />
              </div>
            }
          >
            {/* Signup Tracking */}
            {rightAccess?.some(
              (right) => right.roleName === UserRole.SUPER_ADMINISTRATOR
            ) && (
              <Dropdown.Item
                as={Link}
                to="/signupTracking"
                className={
                  location.pathname === "/signupTracking" ? "active" : ""
                }
              >
                <div className="imgmanagment">
                  <div className="img-pnl">
                    <img className="SignUpManagment" src={tarck} alt="tarck" />
                  </div>
                  Signup Tracking
                </div>
              </Dropdown.Item>
            )}

            {/* Last Active Users */}
            {rightAccess?.some(
              (right) => right?.roleName === UserRole.SUPER_ADMINISTRATOR
            ) && (
              <Dropdown.Item
                as={Link}
                to="/LastActiveUser"
                className={
                  location.pathname === SideBar.UserPoints ? "active" : ""
                }
              >
                <div className="imgmanagment">
                  <div className="img-pnl">
                    <img className="SignUpManagment" src={tarck} alt="tarck" />
                  </div>
                  Last Active Users
                </div>
              </Dropdown.Item>
            )}
          </DropdownButton>
        )}
        {rightAccess?.some(
          (right) => right.roleName === UserRole.SUPER_ADMINISTRATOR
        ) && (
          <DropdownButton
            id="dropdown-basic-button"
            title={
              <div className="Coin-managment">
                <img src={coins} alt="tarck" className="Logo-imageCM" />
                Points Management
                <img
                  src={arrowDown}
                  alt="arrow-down"
                  className="Arrow-DropDown"
                />
              </div>
            }
          >
            <Dropdown.Item>
              {rightAccess?.some(
                (right) => right?.roleName === UserRole.SUPER_ADMINISTRATOR
              ) && (
                <li
                  className={`${
                    location.pathname == SideBar.SEND_COINS ? "active" : ""
                  }`}
                >
                  <Link to="/sendcoins">
                    <div className="img-pnl">
                      <img src={coins} alt="tarck" />
                      <img src={coins} alt="tarck" />
                    </div>{" "}
                    Send Coins
                  </Link>
                </li>
              )}
            </Dropdown.Item>
            <Dropdown.Item>
              {rightAccess?.some(
                (right) => right?.roleName === UserRole.SUPER_ADMINISTRATOR
              ) && (
                <li
                  className={`${
                    location.pathname == SideBar.UserPoints ? "active" : ""
                  }`}
                >
                  <Link to="/UserPoints">
                    <div className="img-pnl">
                      <img src={coins} alt="tarck" />
                      <img src={coins} alt="tarck" />
                    </div>{" "}
                    User Action Logs
                  </Link>
                </li>
              )}
            </Dropdown.Item>
            <Dropdown.Item>
              {rightAccess?.some(
                (right) => right?.roleName === UserRole.SUPER_ADMINISTRATOR
              ) && (
                <li
                  className={`${
                    location.pathname == SideBar.Usertransection ? "active" : ""
                  }`}
                >
                  <Link to="/Usertransection">
                    <div className="img-pnl">
                      <img src={coins} alt="tarck" />
                      <img src={coins} alt="tarck" />
                    </div>{" "}
                    User To User Transaction
                  </Link>
                </li>
              )}
            </Dropdown.Item>
            <Dropdown.Item>
              {rightAccess?.some(
                (right) => right?.roleName === UserRole.SUPER_ADMINISTRATOR
              ) && (
                <li
                  className={`${
                    location.pathname == "/DappTransection" ? "active" : ""
                  }`}
                >
                  <Link to="/DappTransection">
                    <div className="img-pnl">
                      <img src={coins} alt="tarck" />
                      <img src={coins} alt="tarck" />
                    </div>{" "}
                    Dapp Transactions
                  </Link>
                </li>
              )}
            </Dropdown.Item>
          </DropdownButton>
        )}
        {rightAccess?.some(
          (right) => right.roleName === UserRole.SUPER_ADMINISTRATOR
        ) && (
          <DropdownButton
            id="dropdown-basic-button"
            title={
              <div className="Coin-managment">
                <img src={coins} alt="tarck" className="Logo-imageCM" />
                Store Management
                <img
                  src={arrowDown}
                  alt="arrow-down"
                  className="Arrow-DropDown"
                />
              </div>
            }
          >
            <Dropdown.Item>
              {rightAccess?.some(
                (right) => right?.roleName === UserRole.SUPER_ADMINISTRATOR
              ) && (
                <li
                  className={`${
                    location.pathname == SideBar.StoreLinks ? "active" : ""
                  }`}
                >
                  <Link to="/StoreLinks">
                    <div className="img-pnl">
                      <img src={StoreLink} alt="StoreLink" />
                      <img src={StoreLink_white} alt="StoreLink" />
                    </div>
                    Store Links
                  </Link>
                </li>
              )}
            </Dropdown.Item>
            <Dropdown.Item>
              {rightAccess?.some(
                (right) => right?.roleName === UserRole.SUPER_ADMINISTRATOR
              ) && (
                <li
                  className={`${
                    location.pathname == SideBar.UpdateStore ? "active" : ""
                  }`}
                >
                  <Link to="/UpdateStore">
                    <div className="img-pnl">
                      <img src={StoreLink} alt="StoreLink" />
                      <img src={StoreLink_white} alt="StoreLink" />
                    </div>
                    Update store Names
                  </Link>
                </li>
              )}
            </Dropdown.Item>
            <Dropdown.Item>
              {rightAccess?.some(
                (right) => right?.roleName === UserRole.SUPER_ADMINISTRATOR
              ) && (
                <li
                  className={`${
                    location.pathname == SideBar.UpdateProduct ? "active" : ""
                  }`}
                >
                  <Link to="/UpdateProduct">
                    <div className="img-pnl">
                      <img src={StoreLink} alt="StoreLink" />
                      <img src={StoreLink_white} alt="StoreLink" />
                    </div>
                    Update Product & Inventory
                  </Link>
                </li>
              )}
            </Dropdown.Item>
          </DropdownButton>
        )}
        {rightAccess?.some(
          (right) => right.screenName.toLowerCase() === "partner management"
        ) && (
          <li
            className={`${
              location.pathname == "/PartnerManagement" ? "active" : ""
            }`}
          >
            <Link to="/PartnerManagement">
              <div className="img-pnl">
                <img src={iconpartner} alt="Icon Partner" />
                <img src={iconpartnerwhite} alt="Icon Partner" />
              </div>{" "}
              Partner Management
            </Link>
          </li>
        )}
        {rightAccess?.some(
          (right) => right.screenName.toLowerCase() === "subadmin management"
        ) && (
          <li
            className={`${
              location.pathname == "/SubadminManagement" ? "active" : ""
            }`}
          >
            <Link to="/SubadminManagement">
              <div className="img-pnl">
                <img src={iconmanagement} alt="icon  management" />
                <img src={iconmanagementwhite} alt="icon  management" />
              </div>{" "}
              Subadmin Management
            </Link>
          </li>
        )}
        {rightAccess?.some(
          (right) => right?.roleName === UserRole.SUPER_ADMINISTRATOR
        ) && (
          <li
            className={`${
              location.pathname == "/SendNotification" ? "active" : ""
            }`}
          >
            <Link to="/SendNotification">
              <div className="img-pnl">
                <img
                  src={notificationcoloricon}
                  width={22}
                  height={26}
                  alt="icon  management"
                />
                <img
                  src={notificationiconwhite}
                  width={22}
                  height={26}
                  alt="icon  management"
                />
              </div>
              Send Notification
            </Link>
          </li>
        )}
        {rightAccess?.some(
          (right) => right.roleName === UserRole.SUPER_ADMINISTRATOR
        ) && (
          <li
            className={`${
              location.pathname === SideBar.BannerGeneration ? "active" : ""
            }`}
          >
            <Link to="/BannerGeneration">
              <div className="img-pnl">
                <img
                  src={iconbanner}
                  width={22}
                  height={26}
                  alt="icon management"
                />
                <img
                  src={iconbannerwhite}
                  width={22}
                  height={26}
                  alt="icon management"
                />
              </div>
              Banner Generation
            </Link>
          </li>
        )}

        <li className="mobile-view">
          <Link to="/">
            <div className="img-pnl">
              <i className="fa fa-arrow-circle-o-right"></i>
            </div>{" "}
            Logout
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
