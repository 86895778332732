import React from 'react'
import { Button, Form } from 'react-bootstrap'

export function Searchbar({ setsearchedString, handleSearch, searchedString }) {
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };
    return (
        <Form.Group controlId="formBasicEmail">
            <div className="input-group">
                <Form.Control
                    onChange={(e) => setsearchedString(e.target.value)}
                    type="search"
                    placeholder="Search Name"
                    className="form-control"
                    value={searchedString}
                    onKeyPress={handleKeyPress}
                />
                <div className="input-group-append">
                    <Button className="reg-btn" onClick={handleSearch}>
                        <i className="fa fa-search"></i>
                    </Button>
                </div>
            </div>
        </Form.Group>
    )
}
