import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import Header from "./header";
import { Breadcrumb, Button, Form, Modal, Table, Tabs } from "react-bootstrap";
import CustomLoader from "./CustomeLoader";
import moment from "moment";
import {
  API_URL,
  CompanyLocation,
  GetAllLocations,
  LOG_DETAILS_REGEX,
  Node_BE_URL,
  PHONE_NUMBER_REGEX,
  validateEmail,
} from "../constant";
import PaginationComponent from "../pages/PaginationComponent";
import { toast } from "react-toastify";
import { fetchLogs } from "../shared/api";
import coins from "../assets/images/1cmtokenlogo.png";
import CopyIcon from "../assets/images/icons/copy.png";
import { DateRange } from "react-date-range";
import { enUS } from "date-fns/locale";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

const UserPoints = () => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState("");
  const itemsPerPage = 20;
  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
    },
  ]);
  const [expandedUsers, setExpandedUsers] = useState(new Set());

  const loadLogs = async (
    startDate = null,
    endDate = null,
    searchTerm = ""
  ) => {
    setLoading(true);
    try {
      const { logs, totalLogs, message } = await fetchLogs(
        Node_BE_URL,
        currentPage,
        itemsPerPage,
        startDate ? moment(startDate).format("YYYY-MM-DD") : null,
        endDate ? moment(endDate).format("YYYY-MM-DD") : null,
        searchTerm
      );

      if (message === "No logs found") {
        toast.error("Invalid email");
      } else {
        setLogs(logs);
        setTotalItems(totalLogs);
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        toast.error("Invalid email");
      } else {
        console.error("Failed to load logs:", error);
        toast.error("An error occurred while loading logs.");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const selectedRange = dateRange[0];
    loadLogs(selectedRange.startDate, selectedRange.endDate, searchTerm);
  }, [currentPage, itemsPerPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber); // Make sure this updates the state
  };

  const handleCopyGluserId = (gluserId) => {
    if (gluserId) {
      navigator.clipboard.writeText(gluserId);
      toast.success(`GluserId copied to clipboard!`, {
        autoClose: 2000,
      });
    } else {
      toast.error("No gluserId available to copy.");
    }
  };
  const handleFilter = () => {
    const selectedRange = dateRange[0];
    const startDate = selectedRange.startDate || new Date();
    const endDate = selectedRange.endDate || new Date();
    setShowModal(false);
    loadLogs(startDate, endDate);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setDateRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
  };
  const handleSearch = () => {
    if (error) {
      toast.error("Please enter a valid email address or phone number");
      return;
    }
    // Reset to first page when searching
    setCurrentPage(1);
    // If searchTerm contains only numbers, it's a phone number
    const isPhoneNumber = PHONE_NUMBER_REGEX?.test(searchTerm); // Pass the search term to loadLogs
    loadLogs(null, null, searchTerm.trim());
  };

  // If value is not empty.
  // If value is not a valid email (using validateEmail(value), which is assumed to be another function handling email validation).
  // If both conditions are met, it means the input is invalid
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    // Check if input is a phone number (simple validation for numbers only)
    const isPhoneNumber = PHONE_NUMBER_REGEX?.test(value);

    if (value && !isPhoneNumber && !validateEmail(value)) {
      setError("Please enter a valid email address or phone number");
    } else {
      setError("");
    }
  };

  // The function returns an object where:
  // Each key is a userEmail.
  // Each value is an array of logs associated with that user.
  // Logs without an email are grouped under "N/A".
  const groupLogsByUser = (logs) => {
    return logs?.reduce((acc, log) => {
      const email = log.userEmail || "N/A";
      if (!acc[email]) {
        acc[email] = [];
      }
      acc[email].push(log);
      return acc;
    }, {});
  };

  const toggleUserExpansion = (identifier) => {
    setExpandedUsers((prev) => {
      const newSet = new Set(prev);
      if (newSet?.has(identifier)) {
        newSet?.delete(identifier);
      } else {
        newSet?.add(identifier);
      }
      return newSet;
    });
  };
  const formatLogDetails = (details) => {
    if (!details) return "N/A";
  
    // Insert spaces before capital letters
    const formattedDetails = details.replace(/([a-z])([A-Z])/g, '$1 $2');
  
    return LOG_DETAILS_REGEX.reduce(
      (acc, { pattern, replacement }) => acc.replace(pattern, replacement),
      formattedDetails
    ).trim();
  }
  return (
    <>
      <Sidebar />
      <CustomLoader isLoading={loading} />
      <CustomLoader isLoading={loading} />

      <main className="dasboard-main">
        <div className="dashboard-inner-pnl">
          <Header />
          <div className="head-inner-panel ">
            <div className="full-div">
              {/* BreadCrumb */}
              <Breadcrumb>
                <Breadcrumb.Item> Admin Management </Breadcrumb.Item>
                <Breadcrumb.Item href="#">User Logs</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="full-div mt-4">
              <div className="flex-div-sm">
                <h1 className="Activityhistory">User Logs</h1>

                <ul className="btn-lister">
                  <li>
                    <div className="search-pnl">
                      <div className="Date-Range">
                        <Button
                          className="reg-btn"
                          onClick={() => setShowModal(true)}
                          style={{ marginRight: "10px" }}
                        >
                          <i className="fa fa-plus"></i>Select Date Range
                        </Button>
                        <Form
                          onSubmit={(e) => {
                            e.preventDefault(); // Prevent form default submission
                            handleSearch(); // Trigger the search functionality
                          }}
                        >
                          <div>
                            <Form.Group controlId="formBasicEmail">
                              <div className="input-group">
                                <Form.Control
                                  type="search"
                                  placeholder="Enter Email or Phone"
                                  className="form-control"
                                  value={searchTerm}
                                  onChange={handleInputChange}
                                />
                                <div className="input-group-append">
                                  <Button
                                    className="reg-btn"
                                    type="submit" // Submit the form on button click
                                  >
                                    <i className="fa fa-search"></i>
                                  </Button>
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                        </Form>
                      </div>
                      {error && (
                        <Form.Text className="text-danger">{error}</Form.Text>
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="white-div">
              <div className="table-container">
                <div className="table-container-inner">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th></th>
                        <th>User Name / GluserId</th>
                        <th>User Email</th>
                        <th>Company Name</th>
                        <th>Actions</th>
                        <th>Details</th>
                        <th>Amount</th>
                        <th>Timestamp</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.entries(groupLogsByUser(logs))?.map(
                        ([email, userLogs]) => {
                          const detailsMap = userLogs?.reduce((acc, log) => {
                            const details = log?.Details || "No Record Found";
                            const amount = log?.amount || "No Record Found";
                            const key = `${details}-${amount}`;
                            if (!acc[key]) {
                              acc[key] = [];
                            }
                            acc[key].push(log);
                            return acc;
                          }, {});

                          return (
                            <React.Fragment key={email}>
                              {Object.entries(detailsMap)?.map(([detailsAmount, logsWithSameDetails]) => (
                                <React.Fragment key={detailsAmount}>
                                  <tr>
                                    <td>
                                      {logsWithSameDetails.length > 1 && (
                                        <Button
                                          variant="link"
                                          onClick={() => toggleUserExpansion(`${email}-${detailsAmount}`)}
                                          style={{ padding: 0 }}
                                        >
                                          <i
                                            className={`fa fa-chevron-${
                                              expandedUsers.has(`${email}-${detailsAmount}`) ? "up" : "down"
                                            }`}
                                          />
                                        </Button>
                                      )}
                                    </td>
                                    <td style={{ cursor: "pointer" }}>
                                      {logsWithSameDetails[0]?.userName || "No Record Found"} 
                                      <img
                                        onClick={() => handleCopyGluserId(logsWithSameDetails[0].gluserId)}
                                        className="AmmountLogo"
                                        src={CopyIcon}
                                        alt=""
                                      />
                                    </td>
                                    <td>{logsWithSameDetails[0]?.userEmail || "No Record Found"}</td>
                                    <td>
                                      {CompanyLocation[logsWithSameDetails[0]?.companyId]
                                        ? `${CompanyLocation[logsWithSameDetails[0]?.companyId]}-  ${logsWithSameDetails[0]?.companyId}`
                                        : logsWithSameDetails[0]?.companyId || "No Record Found"}
                                    </td>
                                    <td>{logsWithSameDetails[0]?.operation || "No Record Found"}</td>
                                    <td style={{ padding: "10px" }}>{formatLogDetails(detailsAmount.split('-')[0])}</td>
                                    <td>
                                      {logsWithSameDetails[0]?.amount || "No Record Found"}
                                      <img
                                        className="AmmountLogo"
                                        src={coins}
                                        alt="tarck"
                                      />
                                    </td>
                                    <td>
                                      {logsWithSameDetails[0].createdAt
                                        ? moment(logsWithSameDetails[0].createdAt).format("YYYY-MM-DD hh:mm A")
                                        : "No Record Found"}
                                    </td>
                                  </tr>
                                  {expandedUsers?.has(`${email}-${detailsAmount}`) && logsWithSameDetails?.slice(1)?.map((log) => (
                                    <tr key={log._id} style={{ backgroundColor: "#f8f9fa" }}>
                                      <td></td>
                                      <td style={{ cursor: "pointer" }}>
                                        {log?.userName || "No Record Found"}
                                        <img
                                          onClick={() => handleCopyGluserId(log.gluserId)}
                                          className="AmmountLogo"
                                          src={CopyIcon}
                                          alt=""
                                        />
                                      </td>
                                      <td>{log?.userEmail || "No Record Found"}</td>
                                      <td>
                                        {CompanyLocation[log?.companyId]
                                          ? `${CompanyLocation[log?.companyId]}-  ${log?.companyId}`
                                          : log?.companyId || "No Record Found"}
                                      </td>
                                      <td>{log?.operation || "No Record Found"}</td>
                                      <td style={{ padding: "10px" }}>{formatLogDetails(log?.Details) || "No Record Found"}</td>
                                      <td>
                                        {log?.amount || "No Record Found"}
                                        <img
                                          className="AmmountLogo"
                                          src={coins}
                                          alt="tarck"
                                        />
                                      </td>
                                      <td>
                                        {log.createdAt
                                          ? moment(log.createdAt).format("YYYY-MM-DD hh:mm A")
                                          : "No Record Found"}
                                      </td>
                                    </tr>
                                  ))}
                                </React.Fragment>
                              ))}
                            </React.Fragment>
                          );
                        }
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
              <div className="full-div text-center pagination-container">
                {totalItems === 0 ? (
                  <p>No logs available.</p>
                ) : (
                  <PaginationComponent
                    totalItems={totalItems}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                    customClassNames={{
                      rpbItemClassName: "custom-item",
                      rpbItemClassNameActive: "custom-item--active",
                      rpbGoItemClassName: "custom-go-item",
                      rpbItemClassNameDisable: "custom-item--disable",
                      rpbProgressClassName: "custom-progress-bar",
                      rpbRootClassName: "custom-root",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal centered show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header>
            <Button
              closeButton
              className="close-btn"
              onClick={handleCloseModal}
            >
              <i className="fa fa-close"></i>
            </Button>

            <Modal.Title>Select Date Range</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="date-picker-container">
              <DateRange
                ranges={dateRange} // Pass the ARRAY
                onChange={(item) => setDateRange([item.selection])} // Correct update: NEW ARRAY
                locale={enUS}
                editableDateInputs={true}
                moveRangeOnFirstSelection={false}
                months={1}
                direction="horizontal"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="primary" onClick={handleFilter}>
              Filter
            </Button>
          </Modal.Footer>
        </Modal>
      </main>
    </>
  );
};

export default UserPoints;
