import React from "react";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/header";
import { Breadcrumb, Button, Form } from "react-bootstrap";
import { useState } from "react";
import CustomLoader from "../Components/CustomeLoader";
import {
  API_ENDPOINTS,
  emailnumerRegex,
  nameRegex,
  Node_BE_URL,
  phonenumberRegex,
} from "../constant";

const LastActiveUser = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [searched, setSearched] = useState(false);
  const [lastSearched, setLastSearched] = useState("");
  const [error, setError] = useState("");

  // Clears any previous errors.
  // Ensures the input is not empty.
  // Checks if the input is a valid email, phone number, or name.
  // Returns true if valid, otherwise sets an error message and returns false.
  const validateSearch = (value) => {
    // Clear previous error
    setError("");
    if (!value.trim()) {
      setError("Please enter a search term");
      return false;
    }

    // Check if input matches any of the valid formats
    if (
      emailnumerRegex.test(value) ||
      phonenumberRegex.test(value) ||
      nameRegex.test(value)
    ) {
      return true;
    } else {
      setError("Please enter a valid email, phone number, or name");
      return false;
    }
  };
  // Validates the search input.
  // Sets loading states to indicate a search is in progress.
  // Sends an API request to fetch user data based on the search query.
  // Handles the response and updates user data.
  // Clears the search field upon successful data retrieval.
  // Handles errors if the request fails.
  // Ensures the loading state is reset after the operation is completed.
  const handleSearch = async () => {
    if (!validateSearch(searchQuery)) return;

    setLoading(true);
    setSearched(true);
    setLastSearched(searchQuery);
    try {
      const response = await fetch(
        `${Node_BE_URL}${API_ENDPOINTS.GET_LAST_ACTIVE_USER}?search=${searchQuery}`
      );
      const data = await response.json();
      setUserData(data.user);
      setSearchQuery(""); // Clear the search field on success
    } catch (error) {
      console.error("Error fetching data:", error);
      setUserData(null);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* <CustomLoader isLoading={loader} /> */}

      <Sidebar />
      <main className="dasboard-main">
        <div className="dashboard-inner-pnl">
          <Header />
          <div className="head-inner-panel ">
            <div className="full-div">
              {/* BreadCrumb */}
              <Breadcrumb>
                <Breadcrumb.Item>Admin Management</Breadcrumb.Item>
                <Breadcrumb.Item href="#">Last Active User</Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="full-div">
              <div className="flex-div-sm">
                <h1>
                  Last Active User <span className="heading"></span>
                </h1>
                <ul className="btn-lister">
                  <li></li>
                </ul>
              </div>
            </div>

            {loading && <CustomLoader isLoading={loading} />}

            <Form style={{ maxWidth: "900px" }}>
              <Form.Group className="mb-3" controlId="formRecipient">
                <Form.Label>Enter Email, Phone, or Name:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter (Email, Phone, or Name)"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    setError(""); // Clear error when user types
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleSearch();
                    }
                  }}
                  isInvalid={!!error}
                />
                <Form.Control.Feedback type="invalid">
                  {error}
                </Form.Control.Feedback>
              </Form.Group>
              <div className="spacer-40"></div>
              <ul className="half-list">
                <li>
                  <Button
                    className="reg-btn w-100 big"
                    onClick={handleSearch}
                    disabled={loading}
                  >
                    {loading && (
                      <span
                        className="spinner-grow spinner-grow-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    Search
                  </Button>
                </li>
              </ul>
            </Form>

            {searched && (
              <div className="mt-4">
                <h6 className="mb-3">Search Results for: {lastSearched}</h6>
                {userData ? (
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Last Active</th>
                        <th>Total Spend</th>
                        <th>Points</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{userData.name}</td>
                        <td>{userData.email}</td>
                        <td>{userData.phone}</td>
                        <td>
                          {userData.lastActive
                            ? new Date(userData.lastActive).toLocaleString(
                                {
                                  hour: "numeric",
                                  minute: "numeric",
                                  second: "numeric",
                                  hour12: true,
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                  // timeZoneName: "short",
                                }
                              )
                            : "Not Available"}
                        </td>
                        <td>{userData.Totalspend || "N/A"}</td>
                        <td>{userData.Points || "N/A"}</td>
                      </tr>
                    </tbody>
                  </table>
                ) : (
                  <div className="alert alert-info">
                    No records available for this search.
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default LastActiveUser;
