import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Form, Breadcrumb, Button } from "react-bootstrap";
import Sidebar from "../Components/Sidebar";
import Header from "../Components/header";
import { handleGetRole, handleGetUserById } from "../shared/api";
import { toast } from "react-toastify";
import axios from "axios";
import { API_URL } from "../constant";
import CustomLoader from "../Components/CustomeLoader";
function UpdateSubadmin() {
  const { id } = useParams();

  const [role, setrole] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [loader, setloader] = useState(true);
  const [userData, setuserData] = useState({
    firstName: null,
    lastName: null,
    email: null,
    role: null,
    password: null,
    accountType: null,
  });
  useEffect(() => {
    if (id) {
      handleGetUserById(id)
        .then((res) => {
          let data = res.data.data;
          setuserData({
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            role: data.roleId,
            password: data.password,
          });
          setloader(false);
        })
        .catch((erro) => {
          setloader(false);
        });
    }
    handleGetRole()
      .then((res) => {
        setrole(res.data.data);
      })
      .catch((err) => { });
  }, [id]);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleUpdateUser = () => {
    setloader(true);
    const bodyFromData = new FormData();

    bodyFromData.append("Id", id);
    bodyFromData.append("Address", "");
    bodyFromData.append("FirstName", userData.firstName);
    bodyFromData.append("LastName", userData.lastName);
    bodyFromData.append("Email", userData.email);
    bodyFromData.append("Password", userData.password);
    bodyFromData.append("Bio","Bio");
    bodyFromData.append("TwitterLink", "TwitterLink");
    bodyFromData.append("InstagramLink", "InstagramLink");
    bodyFromData.append("RoleId", userData.role);
    bodyFromData.append("RoleName", "RoleName");
    axios
      .post(API_URL + `api/SuperAdmin/SaveUser`, bodyFromData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("Token")}`,
        },
      })
      .then((res) => {
        toast.success("Updated Successfully");
        setloader(false);
        navigate(`/SubadminManagement`);
      })
      .catch((erro) => {
        setloader(false);
      });
  };
  return (
    <>
      <CustomLoader isLoading={loader} />
      <Sidebar />
      <main className="dasboard-main">
        <div className="dashboard-inner-pnl">
          <Header />
          <div className="head-inner-panel">
            <div className="full-div">
              {/* BreadCrum */}
              <Breadcrumb>
                <Breadcrumb.Item> Admin Management </Breadcrumb.Item>
                <Breadcrumb.Item href="/SubadminManagement">
                  Subadmin
                </Breadcrumb.Item>
                <Breadcrumb.Item href="#">Update SubAdmin</Breadcrumb.Item>
              </Breadcrumb>
              {/* BreadCrum */}
            </div>
            <div className="full-div">
              <h1>Update SubAdmin</h1>
              <div className="spacer-20"></div>
            </div>
            <div className="full-div">
              <Form style={{ maxWidth: "900px" }}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>FIRST NAME</Form.Label>
                  <Form.Control
                    value={userData.firstName}
                    name="firstName"
                    type="text"
                    onChange={(e) => {
                      setuserData((prevState) => ({
                        ...prevState,
                        firstName: e.target.value.trimStart(),
                      }));
                    }}
                    placeholder="Enter First name"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>LAST NAME</Form.Label>
                  <Form.Control
                    value={userData.lastName}
                    name="lastName"
                    onChange={(e) => {
                      setuserData((prevState) => ({
                        ...prevState,
                        lastName: e.target.value.trimStart(),
                      }));
                    }}
                    type="text"
                    placeholder="Enter Last name"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>EMAIL</Form.Label>
                  <Form.Control
                    value={userData.email}
                    name="email"
                    type="email"
                    onChange={(e) => {
                      setuserData((prevState) => ({
                        ...prevState,
                        email: e.target.value.trimStart(),
                      }));
                    }}
                    placeholder="Enter Email"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicRole">
                  <Form.Label>Role</Form.Label>
                  <Form.Select
                    className="form-control"
                    name="role"
                    aria-label="Select role"
                    onChange={(e) => {
                      setuserData((prevState) => ({
                        ...prevState,
                        role: e.target.value,
                      }));
                    }}
                  >
                    {role?.map((roleData) => (
                      <option
                        selected={roleData.id === userData.role}
                        value={roleData.id}
                        key={roleData.id}
                      >
                        {roleData.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mb-5" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <div className="button-input-container">
                    <Form.Control
                      value={userData.password}
                      name="password"
                      type={showPassword ? "text" : "password"}
                      onChange={(e) => {
                        setuserData((prevState) => ({
                          ...prevState,
                          password: e.target.value.trimStart(),
                        }));
                      }}
                      placeholder="Password"
                    />
                    <Button onClick={handleTogglePassword}>
                      <i
                        className={showPassword ? "fa fa-eye-slash" : "fa fa-eye"}
                      ></i>
                    </Button>
                  </div>
                </Form.Group>
                <ul className="half-list">
                  <li>
                    <Link
                      onClick={() => handleUpdateUser()}
                      to="#"
                      className="reg-btn w-100 big"
                    >
                      Update Subadmin
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => navigate(-1)}
                      to="#"
                      className="reg-btn w-100 trans big"
                    >
                      Cancel
                    </Link>
                  </li>
                </ul>
              </Form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
export default UpdateSubadmin;
